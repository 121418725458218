import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// auth
import { AuthGuard, RoleBasedGuard } from "src/auth/guard";
// layouts
import DashboardLayout from "src/layouts/dashboard";
// components
import { LoadingScreen } from "src/components/loading-screen";
import { Roles } from "src/auth/context/jwt/auth-provider";
import AssignedTickets from "../../sections/AssignedTickets/AssignedTickets";
import ValidityGuard from "../../auth/guard/validity-guard";

// ----------------------------------------------------------------------

const HandleTicket = lazy(() => import("src/pages/dashboard/HandleTicket"));
const UserTicketHandling = lazy(() =>
  import("src/pages/dashboard/UserTicketHandling")
);
const ClosedTickets = lazy(() => import("src/pages/dashboard/ClosedTickets"));

// ----------------------------------------------------------------------

export const modRoutes = [
  {
    path: "moderators",
    element: (
      <AuthGuard>
        <ValidityGuard>
          <RoleBasedGuard
            hasContent
            roles={[Roles.Mod, Roles.SeniorMod, Roles.System]}
          >
            <DashboardLayout>
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          </RoleBasedGuard>
        </ValidityGuard>
      </AuthGuard>
    ),
    children: [
      { index: true, element: <UserTicketHandling /> },
      { path: "assigned-reports", element: <AssignedTickets /> },
      { path: "handle-report/:ticketId", element: <HandleTicket /> },
      { path: "closed-reports", element: <ClosedTickets /> },
    ],
  },
];
