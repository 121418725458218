/* eslint-disable react/prop-types */
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Iconify from "../iconify/iconify";

const SmallPlayerTemplate = ({ name, steamID, tooltip, isGroup = false }) => (
  <Tooltip title={tooltip} arrow placement="top">
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        width: "100%",
      }}
      className="hover"
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body1">
          {name} {isGroup && <Iconify icon="tabler:users-group" />}
        </Typography>
        <Typography variant="caption">{steamID}</Typography>
      </Box>
    </Box>
  </Tooltip>
);

export default SmallPlayerTemplate;
