/* eslint-disable no-fallthrough */
import { Navigate, useRoutes } from "react-router-dom";
//
import { useAuthContext } from "src/auth/hooks";
import { Roles } from "src/auth/context/jwt/auth-provider";
import { useMemo } from "react";
import { mainRoutes } from "./main";

import { authRoutes } from "./auth";
import { userRoutes } from "./UserRoutes";
import { modRoutes } from "./ModRoutes";
import { seniorRoutes } from "./SeniorRoutes";
import { paths } from "../paths";
import { systemRoutes } from "./SystemRoutes";

// ----------------------------------------------------------------------

const getPathAfterLogin = (role) => {
  switch (role) {
    case Roles.User:
      return paths.user.myReports;
    case Roles.Mod:
    case Roles.SeniorMod:
    case Roles.System:
    case Roles.lab215:
      return paths.mod.reports;
    default:
      return paths.auth.jwt.login;
  }
};

export default function Router() {
  const { user } = useAuthContext();
  const path = useMemo(() => getPathAfterLogin(user?.role), [user?.role]);
  return useRoutes([
    {
      path: "/",
      element: <Navigate to={path} replace />,
    },

    // Auth routes
    ...authRoutes,

    //  user routes
    ...userRoutes,

    // moderator routes
    ...modRoutes,

    //  activity routes
    ...seniorRoutes,

    // systems routes
    ...systemRoutes,

    // Main routes - For now includes 404 only
    ...mainRoutes,

    // No match 404
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
