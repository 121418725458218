import LabelTemplate, { statusList, unkownStatus } from "../LabelTemplate";

export default function UserStatusTemplate({ status }) {
  if (status === "INVESTIGATING") {
    status = "PENDING";
  } else if (status === "EXPIRED") {
    status = "CLOSED";
  }

  const foundStatus = statusList.find((s) => s.text === status) || unkownStatus;
  return <LabelTemplate text={foundStatus.text} color={foundStatus.color} />;
}
