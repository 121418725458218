import { useAuthContext } from "../hooks";
import { useEffect } from "react";
import { ReportsAPI } from "../AxiosAPI";
import { useLocation } from "react-router";

export default function ValidityGuard({ children }) {
  const { user, logout } = useAuthContext();
  const location = useLocation();

  const userId = user?.id;
  const userRole = user?.role;

  useEffect(() => {
    if (userRole === "user") {
      return <>{children}</>;
    }

    ReportsAPI.get(`/admin/moderators/${userId}/validate`)
      .then((response) => {
        const { validity } = response.data;

        if (validity === "invalid") {
          localStorage.removeItem("accessToken");
          logout();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [children, logout, userId, userRole, location]);

  return <>{children}</>;
}
