import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// auth
import { AuthGuard, RoleBasedGuard } from "src/auth/guard";
// layouts
import DashboardLayout from "src/layouts/dashboard";
// components
import { LoadingScreen } from "src/components/loading-screen";
import { Roles } from "src/auth/context/jwt/auth-provider";

// ----------------------------------------------------------------------

const MyTickets = lazy(() => import("src/pages/dashboard/MyTickets"));
const CreateTicket = lazy(() => import("src/pages/dashboard/CreateTicket"));
const TicketInformation = lazy(() =>
  import("src/pages/dashboard/TicketInformation")
);

// ----------------------------------------------------------------------

export const userRoutes = [
  {
    path: "user",
    element: (
      <AuthGuard>
        <RoleBasedGuard hasContent roles={[Roles.User]}>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </RoleBasedGuard>
      </AuthGuard>
    ),
    children: [
      { element: <MyTickets />, index: true },
      { path: "create-report", element: <CreateTicket /> },
      { path: "report-info/:ticketId", element: <TicketInformation /> },
    ],
  },
];
