import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// auth
import { AuthGuard, RoleBasedGuard } from "src/auth/guard";
// layouts
import DashboardLayout from "src/layouts/dashboard";
// components
import { LoadingScreen } from "src/components/loading-screen";
import { Roles } from "src/auth/context/jwt/auth-provider";
import ValidityGuard from "../../auth/guard/validity-guard";

// ----------------------------------------------------------------------

const ControlPanel = lazy(() => import("src/pages/dashboard/ControlPanel"));

// ----------------------------------------------------------------------

export const systemRoutes = [
  {
    path: "systems",
    element: (
      <AuthGuard>
        <ValidityGuard>
          <RoleBasedGuard hasContent roles={[Roles.System]}>
            <DashboardLayout>
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          </RoleBasedGuard>
        </ValidityGuard>
      </AuthGuard>
    ),
    children: [{ element: <ControlPanel />, index: true }],
  },
];
