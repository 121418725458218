export function loadPreferences(page) {
  try {
    const preferences = localStorage.getItem(`preferences-${page}`);

    if (!preferences) {
      return null;
    }

    return JSON.parse(preferences);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function savePreferences(page, preferences) {
  localStorage.setItem(`preferences-${page}`, JSON.stringify(preferences));
}
