/* eslint-disable react/prop-types */
// @mui
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
// components
import { useSettingsContext } from "src/components/settings";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";
import Iconify from "src/components/iconify/iconify";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { ReportsAPI } from "src/auth/AxiosAPI";
import ViewTemplate from "src/components/GridCellTemplates/ViewTemplate";
import { ROOTS } from "src/routes/paths";
import {
  ageProps,
  getSuspectsQuery,
  moderatorProps,
  serverProps,
  statusProps,
  suspectProps,
  viewProps,
} from "src/components/GridColumnProps";
import useSocket from "src/hooks/useSocket";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router";
import { useAuthContext } from "src/auth/hooks";
import Label from "src/components/label/label";
import CircularProgress from "@mui/material/CircularProgress";
import { webSocketURL } from "../../config/config";
// import { Button, Menu } from "@mui/material";
// import MenuItem from "@mui/material/MenuItem";
// import postBattlemetricsNote from "../../utils/postBattlemetricsNote";
import {
  loadPreferences,
  savePreferences,
} from "../../utils/cached-preferences";

// ----------------------------------------------------------------------

const NewViewTemplate = ({ row, emitAssignTicket }) => {
  const [assigning, setAssigning] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const onAssign = useCallback(async () => {
    setAssigning(true);

    try {
      const { status, message } = await emitAssignTicket({
        ticketId: row.ticketId,
      });
      if (status !== "success") {
        enqueueSnackbar({
          message: message || "Could not assign the ticket!",
          variant: "error",
        });
      } else {
        await ReportsAPI.put(`/reports/${row.ticketId}`, {
          moderator_id: user.id,
          status: "INVESTIGATING",
        });
        navigate(
          `${ROOTS.MOD}/handle-report/${row.ticketId}?${getSuspectsQuery(row)}`
        );
      }
    } catch (error) {
      enqueueSnackbar({
        message: error?.message || "Could not assign the ticket!",
        variant: "error",
      });
      setAssigning(false);
    }
  }, [emitAssignTicket, enqueueSnackbar, navigate, row, user.id]);

  return (
    <>
      {assigning ? (
        <CircularProgress color="inherit" variant="indeterminate" size={15} />
      ) : (
        !row.moderator_id && (
          <Tooltip title="Investigate" arrow placement="top">
            <IconButton disabled={assigning} onClick={onAssign}>
              <Iconify icon="tabler:gavel" />
            </IconButton>
          </Tooltip>
        )
      )}

      <ViewTemplate
        href={`${ROOTS.MOD}/handle-report/${row.ticketId}?${getSuspectsQuery(
          row
        )}`}
      />
    </>
  );
};

export default function AssignedTickets() {
  const settings = useSettingsContext();
  const { user } = useAuthContext();
  const [reports, setReports] = useState([]);
  const [fetchedTotal, setFetchedTotal] = useState(10);
  const [loading, setLoading] = useState(true);
  const { closeSnackbar } = useSnackbar();
  const [paginationModel, setPaginationModel] = useState(() => ({
    pageSize:
      loadPreferences("assigned-tickets")?.paginationModel?.pageSize || 10,
    page: 0,
  }));
  // const [showActionButton, setShowActionButton] = useState(false);
  const [gridKey, setGridKey] = useState(0);

  const navigate = useNavigate();

  const [sortModel, setSortModel] = useState(() => [
    { field: "createdAt", sort: "desc" },
  ]);
  const [total, setTotal] = useState(0);
  const [lastSearched, setLastSearched] = useState("");
  // const [selectedRows, setSelectedRows] = useState([]);
  // const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    async function savePreference() {
      const newPagination = {
        pageSize: paginationModel.pageSize,
        page: 0,
      };

      await savePreferences("assigned-tickets", {
        paginationModel: newPagination,
      });
    }
    savePreference();
  }, [paginationModel]);

  const SearchBar = ({
    onSearch = (text) => {},
    disabled,
    onReset = () => {},
    socket,
    emitWithAck,
    updateReports,
    refreshData,
  }) => {
    const ref = useRef(null);
    const { user } = useAuthContext();
    const handleSearch = () => {
      if (ref.current && ref.current.value !== "") {
        onSearch(ref.current.value);
        if (socket) {
          if (ref.current.value.length === 24) {
            emitWithAck(async (s) =>
              s.emitWithAck("get-tickets", {
                status: "OPEN",
                searchQuery: ref.current.value,
              })
            )()
              .then((response) => {
                response.data.reports = response.data.reports.filter(
                  (r) => r.moderator_id === user.id
                );
                updateReports(response.data);
              })
              .catch((error) => {
                console.error("Error emitting get-tickets:", error);
              });
          } else {
            emitWithAck(async (s) =>
              s.emitWithAck("get-unique-tickets", {
                status: "OPEN",
                searchQuery: ref.current.value,
              })
            )()
              .then((response) => {
                response.data.reports = response.data.reports.filter(
                  (r) => r.moderator_id === user.id
                );
                updateReports(response.data);
              })
              .catch((error) => {
                console.error("Error emitting get-unique-tickets:", error);
              });
          }
        }
      }
    };

    return (
      <TextField
        sx={{ width: { xs: "100%", md: "fit-content" }, mb: 2 }}
        label="Search"
        disabled={disabled}
        inputRef={ref}
        onKeyDown={(e) => {
          if (e.key === "Enter" && ref.current && ref.current.value !== "") {
            e.preventDefault();
            handleSearch();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={disabled}
                onClick={() => {
                  if (ref.current) {
                    ref.current.value = "";
                    onReset();
                    refreshData();
                  }
                }}
              >
                <Iconify icon="tabler:refresh" />
              </IconButton>

              <IconButton
                disabled={disabled}
                onClick={() => {
                  handleSearch();
                }}
              >
                <Iconify icon="tabler:search" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
    );
  };

  const { withEmitAck, socket } = useSocket(
    webSocketURL,
    {
      reconnectionAttempts: 0,
      autoConnect: false,
      reconnection: false,
      path: "/staff",
      transports: ["websocket"],
      query: {
        token: ReportsAPI.defaults.headers.common.Authorization.split(" ")[1],
      },
    },
    setLoading,
    (withAck) => {
      setLoading(true);
      withAck(async (s) =>
        s.emitWithAck("get-unique-tickets", {
          ...paginationModel,
          pageSize: paginationModel.pageSize + (fetchedTotal - total),
          sortBy: sortModel[0] ? sortModel[0].sort : undefined,
          status: "INVESTIGATING",
        })
      )()
        .then(({ data }) => {
          if (data) {
            data.reports = data.reports.filter(
              (r) => r.moderator_id === user.id
            );
            setFetchedTotal(data.totalData);
            data.totalData = data.reports.length;
            setReportsAndTotal(data);
          }
        })
        .finally((f) => setLoading(false));

      //  connected
    },
    {
      ticketAssigned: (t) => {
        ticketGotAssigned(t);
      },
      ticketUnassigned: (t) => {
        ticketGotUnassigned(t);
      },
    },
    () => {
      setLoading(false);
      setTotal(0);
      setReports([]);
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emitGetTickets = useCallback(
    withEmitAck(async (s) =>
      s.emitWithAck("get-unique-tickets", {
        ...paginationModel,
        pageSize: paginationModel.pageSize + (fetchedTotal - total),
        sortBy: sortModel[0] ? sortModel[0].sort : undefined,
        status: "INVESTIGATING",
        moderator_id: user.id,
      })
    ),
    [sortModel, paginationModel, withEmitAck]
  );

  const ticketGotAssigned = (ticket) => {
    emitGetTickets()
      .then(({ data }) => {
        setReports((prevReports) =>
          prevReports.map((report) =>
            report.id === ticket.ticketId
              ? {
                  ...report,
                  moderator_id: ticket.moderator_id,
                  status: "INVESTIGATING",
                }
              : report
          )
        );
        setTotal(data.totalData || 0);
        setGridKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const ticketGotUnassigned = (ticket) => {
    emitGetTickets()
      .then(({ data }) => {
        setReports((prevReports) =>
          prevReports.map((report) =>
            report.id === ticket.ticketId
              ? { ...report, moderator_id: null, status: "PENDING" }
              : report
          )
        );
        setTotal(data.totalData || 0);
        setGridKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const setReportsAndTotal = useCallback((data) => {
    const r = data;
    if (r) {
      setReports(
        r.reports?.map((report) => ({ ...report, id: report.ticketId })) || []
      );
      setTotal(r.totalData || 0);
    } else {
      setReports([]);
      setTotal(0);
    }
  }, []);

  const updateReports = (newData) => {
    newData.reports = newData.reports.filter((r) => r.moderator_id === user.id);
    setFetchedTotal(newData.totalData);
    newData.totalData = newData.reports.length;
    setReportsAndTotal(newData);
  };

  const fetchInitialData = () => {
    setLoading(true);
    emitGetTickets()
      .then(({ data }) => {
        if (data) {
          data.reports = data.reports.filter((r) => r.moderator_id === user.id);
          setFetchedTotal(data.totalData);
          data.totalData = data.reports.length;
          setReportsAndTotal(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (socket) {
      fetchInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitGetTickets]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emitAssignTicket = useCallback(
    withEmitAck(async (s, { ticketId }) =>
      s.emitWithAck("assignTicket", { ticketId, moderator_id: user.id })
    ),
    [withEmitAck]
  );

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = async (event, targetReports) => {
  //   setAnchorEl(null);
  //
  //   try {
  //     setLoading(true);
  //     targetReports.map(async (report) => {
  //       const closeReport = await ReportsAPI.put(
  //         `/reports/${report.ticketId}`,
  //         {
  //           moderator_id: user.id,
  //           status: "CLOSED",
  //           action: event,
  //         }
  //       );
  //
  //       const postBmNote = await postBattlemetricsNote(
  //         report.ticketId,
  //         user.displayName,
  //         event,
  //         report.reason,
  //         report.primary_suspect.steam_id,
  //         report.server_id,
  //         null,
  //         true
  //       );
  //
  //       await Promise.allSettled([closeReport, postBmNote]);
  //       setLoading(false);
  //       await fetchInitialData();
  //     });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const columns = useMemo(
    () => [
      {
        ...ageProps,
        renderCell: (params) =>
          params.row.isNew ? (
            <Label color="success">New</Label>
          ) : (
            <Typography variant="body2">{params.formattedValue}</Typography>
          ),
      },

      suspectProps,
      serverProps,

      statusProps,
      moderatorProps,
      {
        ...viewProps,
        headerName: "Action",

        renderCell: ({ row }) => (
          <NewViewTemplate row={row} emitAssignTicket={emitAssignTicket} />
        ),
      },
    ],
    [emitAssignTicket]
  );

  const rowClick = (params, event) => {
    if (event.ctrlKey) {
      window.open(
        `${ROOTS.MOD}/handle-report/${params.row.ticketId}?${getSuspectsQuery(
          params.row
        )}`
      );
    } else {
      navigate(
        `${ROOTS.MOD}/handle-report/${params.row.ticketId}?${getSuspectsQuery(
          params.row
        )}`
      );
    }
  };

  useEffect(() => {
    if (socket) {
      setLoading(true);
      emitGetTickets()
        .then(({ data }) => {
          if (data) {
            data.reports = data.reports.filter(
              (r) => r.moderator_id === user.id
            );
            setFetchedTotal(data.totalData);
            data.totalData = data.reports.length;
            setReportsAndTotal(data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitGetTickets]);
  return (
    <Container maxWidth={settings.themeStretch ? false : "xl"}>
      <Stack spacing={4}>
        <Typography variant="h4">Assigned Reports</Typography>

        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                alignItems="center"
                gap={2}
              >
                <SearchBar
                  onSearch={(text) => {
                    setLastSearched(text);
                    // setShowActionButton(!!text);
                  }}
                  onReset={() => {
                    setLastSearched("");
                    // setShowActionButton(false);
                  }}
                  socket={socket}
                  emitWithAck={withEmitAck}
                  updateReports={updateReports}
                  refreshData={fetchInitialData}
                />
                {/*{showActionButton && (*/}
                {/*  <div>*/}
                {/*    <Button*/}
                {/*      variant="contained"*/}
                {/*      color="primary"*/}
                {/*      onClick={handleClick}*/}
                {/*    >*/}
                {/*      Bulk Action*/}
                {/*    </Button>*/}
                {/*    <Menu*/}
                {/*      anchorEl={anchorEl}*/}
                {/*      keepMounted*/}
                {/*      open={Boolean(anchorEl)}*/}
                {/*      onClose={() => setAnchorEl(null)}*/}
                {/*    >*/}
                {/*      <MenuItem onClick={() => handleClose("BAN", reports)}>*/}
                {/*        Ban*/}
                {/*      </MenuItem>*/}
                {/*      <MenuItem onClick={() => handleClose("KICK", reports)}>*/}
                {/*        Kick*/}
                {/*      </MenuItem>*/}
                {/*      <MenuItem onClick={() => handleClose("MUTE", reports)}>*/}
                {/*        Mute*/}
                {/*      </MenuItem>*/}
                {/*      <MenuItem onClick={() => handleClose("OFFLINE", reports)}>*/}
                {/*        Offline*/}
                {/*      </MenuItem>*/}
                {/*      <MenuItem*/}
                {/*        onClick={() => handleClose("NO_ACTION", reports)}*/}
                {/*      >*/}
                {/*        No Action*/}
                {/*      </MenuItem>*/}
                {/*    </Menu>*/}
                {/*  </div>*/}
                {/*)}*/}
                {/*{selectedRows.length > 0 && (*/}
                {/*  <div>*/}
                {/*    <Button*/}
                {/*      variant="contained"*/}
                {/*      color="primary"*/}
                {/*      onClick={handleClick}*/}
                {/*    >*/}
                {/*      Bulk Action*/}
                {/*    </Button>*/}
                {/*    <Menu*/}
                {/*      anchorEl={anchorEl}*/}
                {/*      keepMounted*/}
                {/*      open={Boolean(anchorEl)}*/}
                {/*      onClose={() => setAnchorEl(null)}*/}
                {/*    >*/}
                {/*      <MenuItem*/}
                {/*        onClick={() => {*/}
                {/*          const selectedReports = reports.filter((report) =>*/}
                {/*            selectedRows.includes(report.ticketId)*/}
                {/*          );*/}
                {/*          handleClose("BAN", selectedReports);*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        Ban*/}
                {/*      </MenuItem>*/}
                {/*      <MenuItem*/}
                {/*        onClick={() => {*/}
                {/*          const selectedReports = reports.filter((report) =>*/}
                {/*            selectedRows.includes(report.ticketId)*/}
                {/*          );*/}
                {/*          handleClose("KICK", selectedReports);*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        Kick*/}
                {/*      </MenuItem>*/}
                {/*      <MenuItem*/}
                {/*        onClick={() => {*/}
                {/*          const selectedReports = reports.filter((report) =>*/}
                {/*            selectedRows.includes(report.ticketId)*/}
                {/*          );*/}
                {/*          handleClose("MUTE", selectedReports);*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        Mute*/}
                {/*      </MenuItem>*/}
                {/*      <MenuItem*/}
                {/*        onClick={() => {*/}
                {/*          const selectedReports = reports.filter((report) =>*/}
                {/*            selectedRows.includes(report.ticketId)*/}
                {/*          );*/}
                {/*          handleClose("OFFLINE", selectedReports);*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        Offline*/}
                {/*      </MenuItem>*/}
                {/*      <MenuItem*/}
                {/*        onClick={() => {*/}
                {/*          const selectedReports = reports.filter((report) =>*/}
                {/*            selectedRows.includes(report.ticketId)*/}
                {/*          );*/}
                {/*          handleClose("NO_ACTION", selectedReports);*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        No Action*/}
                {/*      </MenuItem>*/}
                {/*    </Menu>*/}
                {/*  </div>*/}
                {/*)}*/}
              </Grid>
              {lastSearched && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  display="grid"
                  sx={{ placeContent: "center" }}
                >
                  <Typography textAlign="center">
                    {loading ? "Searching for:" : "Searched for:"}{" "}
                    {lastSearched}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <DataGrid
              getRowId={(row) => row.ticketId}
              key={gridKey}
              checkboxSelection={false}
              disableColumnMenu
              loading={loading}
              rows={reports.filter((report) => report.ticketId)}
              columns={columns}
              onRowSelectionModelChange={(newSelection) => {
                // setSelectedRows(newSelection);
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: paginationModel.pageSize || 100,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, 25]}
              disableRowSelectionOnClick
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={(model) => {
                closeSnackbar();
                setPaginationModel((p) => ({ ...p, page: 0 }));
                setSortModel(model);
              }}
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={(m) => {
                closeSnackbar();
                setPaginationModel(m);
              }}
              onRowClick={(params, event) => rowClick(params, event)}
              rowCount={total}
              sx={{ height: "auto", maxHeight: "75vh" }}
            />
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
}
