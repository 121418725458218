export async function getContentType(url) {
  // Domain-specific rules
  if (url.includes("youtube.com/watch") || url.includes("youtu.be/")) {
    return "video";
  }
  if (url.includes("twitch.tv/")) {
    return "video";
  }

  // File extension
  const extensionMatch = url.match(/\.[a-zA-Z0-9]+(\?|$)/);
  if (extensionMatch) {
    const extension = extensionMatch[0].toLowerCase();
    if ([".jpg", ".jpeg", ".png", ".gif"].includes(extension)) {
      return "image";
    }
    if ([".mp4", ".avi", ".mkv"].includes(extension)) {
      return "video";
    }
  }

  // Content-Type header
  try {
    const response = await fetch(url, { method: "HEAD" });
    const contentType = response.headers.get("Content-Type");
    if (contentType.startsWith("image/")) {
      return "image";
    }
    if (contentType.startsWith("video/")) {
      return "video";
    }
  } catch (error) {
    console.error("Failed to fetch Content-Type:", error);
  }

  return "video";
}
