import PropTypes from "prop-types";
import { useEffect } from "react";
// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
// hooks
import { useResponsive } from "src/hooks/use-responsive";
// components
import Logo from "src/components/logo";
import Scrollbar from "src/components/scrollbar";
import { usePathname } from "src/routes/hook";
import { NavSectionVertical } from "src/components/nav-section";
//
import { useAuthContext } from "src/auth/hooks";
import { NAV } from "../config-layout";
import { useNavData } from "./config-navigation";
import { Button } from "@mui/material";
import { ReportsAPI } from "../../auth/AxiosAPI";
import { enqueueSnackbar } from "notistack";
import { NavToggleButton } from "../_common";

// ----------------------------------------------------------------------

export default function NavVertical({ openNav, onCloseNav }) {
  const { user } = useAuthContext();

  const pathname = usePathname();

  const lgUp = useResponsive("up", "lg");

  const navData = useNavData();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const discordClick = () => {
    window.location.href = `https://reports-api.rustafied.com/api/v1/auth/discord/login?steamId=${user?.id}`;
  };

  const whitelistClick = async () => {
    const response = await ReportsAPI.post(`/whitelist/${user?.id}`);

    if (response.status === 200) {
      enqueueSnackbar({
        message: "You are already whitelisted!",
        variant: "success",
      });
    }

    if (response.status === 201) {
      enqueueSnackbar({
        message: "You have been whitelisted!",
        variant: "success",
      });
    }

    if (response.status === 400) {
      enqueueSnackbar({
        message: "Error issuing whitelist!",
        variant: "error",
      });
    }
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />
      <NavSectionVertical
        data={navData}
        config={{
          currentRole: user?.role || "Unkown",
        }}
      />
      {user?.role === "user" && (
        <Box
          sx={{
            mt: "auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="success"
            sx={{ mb: 2, mx: "auto", width: "80%" }}
            onClick={whitelistClick}
          >
            Whitelist
          </Button>
          <Button
            variant="contained"
            disabled={user?.discord}
            color="success"
            sx={{ mb: 2, mx: "auto", width: "80%" }}
            onClick={discordClick}
          >
            {user?.discord ? "Discord Linked" : "Link Discord"}
          </Button>
        </Box>
      )}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton
        onCloseNav={onCloseNav}
        sx={{
          top: 22,
          left: NAV.W_VERTICAL - 13,
        }}
      />
      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  onCloseNav: PropTypes.func,
  openNav: PropTypes.bool,
};
