import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";

export const NoteCell = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Typography fontSize={22}>
      {row?.notes ? (
        <>
          <IconButton aria-describedby={id} onClick={handleClick}>
            <FontAwesomeIcon icon={faNoteSticky} />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div style={{ maxWidth: "800px", overflow: "auto" }}>
              {" "}
              <Typography sx={{ p: 2 }}>{row.notes}</Typography>
            </div>
          </Popover>
        </>
      ) : (
        "---"
      )}
    </Typography>
  );
};
