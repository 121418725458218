/* eslint-disable no-restricted-syntax */
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import { useSnackbar } from "notistack";
import { useEffect, useRef, useCallback } from "react";
import socketIOClient from "socket.io-client";
import Iconify from "src/components/iconify/iconify";

// Helper function
const checkSocket = (socket, enqueueSnackbar) => {
  if (!socket) {
    enqueueSnackbar({
      message: "No socket io connection!",
      variant: "error",
    });
    return false;
  }
  return true;
};

//  This hook establishes a socket io connection, and handles common events
//  an example of events:{'newConversation': handleNewConversation,'name':handler}
function useSocket(
  url,
  options,
  setLoading = () => {},
  onConnected = (...args) => {},
  events = {},
  onError = () => {}
) {
  const isDisconnectingOnNavigate = useRef(false);

  const socketRef = useRef();
  const heartbeatInterval = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const withEmitAck = useCallback(
    (emitFn) =>
      async (...args) => {
        const socket = socketRef.current;
        if (!checkSocket(socket, enqueueSnackbar))
          return { data: undefined, message: undefined, status: undefined };

        try {
          const { status, data, message } = await emitFn(socket, ...args);
          if (status !== "success") {
            throw new Error(message || "Failed to get data!");
          }
          return { data, message, status };
        } catch (error) {
          enqueueSnackbar({
            message: error.message || "Error",
            variant: "error",
          });
        }
        return { data: undefined, message: undefined, status: undefined };
      },
    [enqueueSnackbar]
  );

  const withEmit = useCallback(
    (emitFn) =>
      (...args) => {
        const socket = socketRef.current;
        if (!checkSocket(socket, enqueueSnackbar)) return;
        try {
          emitFn(socket, ...args);
        } catch (error) {
          enqueueSnackbar({
            message: error.message || "Error",
            variant: "error",
          });
        }
      },
    [enqueueSnackbar]
  );

  const reconnect = useCallback(() => {
    const socket = socketRef.current;
    if (!socket) {
      enqueueSnackbar({
        message: "Could not reconnect, no socket io!",
        variant: "error",
      });
      return;
    }
    setLoading(true);
    socket.connect();
  }, [enqueueSnackbar, setLoading]);

  const reconnectSnackbar = (message) => {
    enqueueSnackbar({
      message,
      key: Math.random(),
      variant: "error",
      persist: true,
      action: (key) => (
        <Stack flexDirection="row" spacing={1}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              reconnect();
              closeSnackbar(key);
            }}
          >
            Reconnect
          </Button>
          <IconButton onClick={() => closeSnackbar(key)}>
            <Iconify icon="tabler:x" />
          </IconButton>
        </Stack>
      ),
    });
  };

  const establishSocketIO = () => {
    socketRef.current = socketIOClient(url, {
      reconnectionAttempts: 0,
      autoConnect: false,
      reconnection: false,
      ...options,
    });
    const socket = socketRef.current;

    // Attach event listeners
    socket.on("connect", (...args) => {
      onConnected(withEmitAck, ...args);
      heartbeatInterval.current = setInterval(() => {
        socket.emit("heartbeat");
      }, 20000);
    });

    socket.on("disconnect", () => {
      onError();
      if (!isDisconnectingOnNavigate.current) {
        reconnectSnackbar("Disconnected from server");
      }
      if (heartbeatInterval.current) {
        clearInterval(heartbeatInterval.current);
      }
    });

    socket.on("connect_error", (error) => {
      onError();
      reconnectSnackbar("Connection Error!");
    });
    socket.on("connect_timeout", (error) => {
      onError();
      reconnectSnackbar("Connection time out!");
    });

    socket.on("error", (error) => {
      onError();
      enqueueSnackbar({
        message: error.message || "Unkown Error!",
        variant: "error",
      });
    });

    for (const [eventName, eventHandler] of Object.entries(events)) {
      socket.on(eventName, eventHandler);
    }
    socket.connect();
    return socket;
  };

  useEffect(() => {
    const s = establishSocketIO();

    return () => {
      isDisconnectingOnNavigate.current = true;
      if (heartbeatInterval.current) {
        clearInterval(heartbeatInterval.current);
      }
      setTimeout(() => {
        s.disconnect();
      }, 500);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { socket: socketRef.current, withEmit, withEmitAck };
}

export default useSocket;
