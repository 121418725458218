/* eslint-disable react/prop-types */
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import React, { createContext, useContext, useMemo, useState } from 'react'
import Iconify from '../iconify/iconify'

const Transition = React.forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />)

const DialogContext = createContext({
  openDialog: ({
    header,
    body,
    footer,
    disableBackdropClose = false,
    disableCloseButton = false,
    disableEscapeClose = false,
  }) => {},
  closeDialog: () => {},
})

export const useDialog = () => useContext(DialogContext)

export const DialogProvider = ({ children }) => {
  const [dialog, setDialog] = useState(null)
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
    setTimeout(() => setDialog(null), 300)
  }
  const value = useMemo(
    () => ({
      closeDialog: handleClose,
      openDialog: ({
        header,
        body,
        footer,
        disableBackdropClose = false,
        disableCloseButton = false,
        disableEscapeClose = false,
      }) => {
        setDialog({
          header,
          body,
          footer,
          disableBackdropClose,
          disableCloseButton,
          disableEscapeClose,
        })
        setOpen(true)
      },
    }),
    []
  )

  return (
    <DialogContext.Provider value={value}>
      <Dialog
        disableEscapeKeyDown={dialog?.disableEscapeClose}
        maxWidth='md'
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e, r) => {
          if (dialog?.disableBackdropClose && r === 'backdropClick') {
            return
          }
          handleClose()
        }}
      >
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          {dialog?.header}
          {!dialog?.disableCloseButton && (
            <IconButton onClick={handleClose}>
              <Iconify icon='tabler:x' />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>{dialog?.body}</DialogContent>
        {dialog?.footer && <DialogActions>{dialog.footer}</DialogActions>}
      </Dialog>

      {children}
    </DialogContext.Provider>
  )
}
