/* eslint-disable react/prop-types */
import React from "react";
import Label from "./label/label";

export const statusList = [
  { text: "OPEN", color: "warning" },
  { text: "PENDING", color: "secondary" },
  { text: "INVESTIGATING", color: "info" },
  { text: "CLOSED", color: "success" },
  { text: "EXPIRED", color: "error" },
];

export const userStatusList = [
  { text: "OPEN", color: "warning" },
  { text: "CLOSED", color: "success" },
];

export const unkownStatus = { text: "Unkown", color: "error" };

export const reasons = [
  {
    label: "Hacking",
    icon: "tabler:terminal-2",
    value: "HACKING",
    color: "warning",
  },
  {
    label: "Association",
    icon: "tabler:users-plus",
    value: "ASSOCIATION",
    color: "info",
  },
  {
    label: "Ban Evasion",
    icon: "tabler:lock-off",
    value: "BAN_EVASION",
    color: "secondary",
  },
  {
    label: "Teaming",
    icon: "tabler:users-group",
    value: "TEAMING",
    color: "info",
  },
  {
    label: "Toxic Behaviour",
    icon: "tabler:biohazard",
    value: "TOXIC_BEHAVIOUR",
    color: "error",
  },
  {
    label: "Other",
    icon: "tabler:question-mark",
    value: "OTHER",
    color: "success",
  },
];

export const unkownReason = {
  label: "Unknown",
  icon: "tabler:question-mark",
  value: "unknown",
  color: "error",
};

export const actions = [
  { value: "BANNED", label: "Ban" },
  { value: "KICKED", label: "Kick" },
  { value: "MUTED", label: "Mute" },
  { value: "OFFLINE", label: "Offline" },
  { value: "NO_ACTION", label: "Investigated" },
];

export default function LabelTemplate({
  color = "primary",
  text = "Unkown",
  minWidth = 105,
}) {
  return (
    <Label sx={{ minWidth }} color={color}>
      {text}
    </Label>
  );
}
