// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

// routes
import Router from "src/routes/sections";
// theme
import ThemeProvider from "src/theme";
// hooks
import { useScrollToTop } from "src/hooks/use-scroll-to-top";
// components
import ProgressBar from "src/components/progress-bar";
import MotionLazy from "src/components/animate/motion-lazy";
import { SettingsProvider } from "src/components/settings";
// auth
import { AuthProvider, AuthConsumer } from "src/auth/context/jwt";

import "./global.css";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { SnackbarProvider, useSnackbar } from "./components/snackbar";
import { DialogProvider } from "./components/Dialog/Dialog";

// ----------------------------------------------------------------------

const CloseSnackbarOnPathChange = ({ children }) => {
  const location = useLocation();
  const { closeSnackbar } = useSnackbar();
  useEffect(() => {
    closeSnackbar();
  }, [closeSnackbar, location.pathname]);
  return children;
};

export default function App() {
  useScrollToTop();

  return (
    <SettingsProvider
      defaultSettings={{
        themeMode: "dark", // 'light' | 'dark'
        themeDirection: "ltr", //  'rtl' | 'ltr'
        themeContrast: "bold", // 'default' | 'bold'
        themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
        themeColorPresets: "cyan", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
        themeStretch: false,
      }}
    >
      <ThemeProvider>
        <SnackbarProvider>
          <AuthProvider>
            <MotionLazy>
              <DialogProvider>
                <CloseSnackbarOnPathChange>
                  <ProgressBar />
                  <AuthConsumer>
                    <Router />
                  </AuthConsumer>
                </CloseSnackbarOnPathChange>
              </DialogProvider>
            </MotionLazy>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </SettingsProvider>
  );
}
