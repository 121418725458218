import axios from "axios";

export const ReportsAPI = axios.create({
  baseURL: "https://reports-api.rustafied.com/api/v1",
});

export const RustafiedAPI = axios.create({
  baseURL: "https://rustafied-api.devoceansolutions.eu/api/v1/",
});

export const ContextAPI = axios.create({
  baseURL: "https://skynet-api.rustafied.com/api/v1",
});
