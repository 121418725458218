import Switch from "@mui/material/Switch";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import SvgColor from "src/components/svg-color/svg-color";
import { useSettingsContext } from "src/components/settings";

export default function ThemeModeToggle() {
  const theme = useTheme();
  const settings = useSettingsContext();
  const isDark = settings.themeMode === "dark";
  const secondary = theme.palette.secondary.main;
  const grey = theme.palette.grey[500];

  return (
    <Stack flexDirection="row" alignItems="center">
      <SvgColor
        className="hover"
        onClick={() => settings.onUpdate("themeMode", "light")}
        src="/assets/icons/setting/ic_sun.svg"
        color={isDark ? grey : secondary}
      />
      <Switch
        checked={isDark}
        onChange={(e) =>
          settings.onUpdate("themeMode", e.target.checked ? "dark" : "light")
        }
      />
      <SvgColor
        className="hover"
        onClick={() => settings.onUpdate("themeMode", "dark")}
        src="/assets/icons/setting/ic_moon.svg"
        color={isDark ? secondary : grey}
      />
    </Stack>
  );
}
