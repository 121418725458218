import PropTypes from "prop-types";
import { useEffect, useReducer, useCallback, useMemo } from "react";
// utils
import axios, { endpoints } from "src/utils/axios";
//
import { useApiErrorHandler } from "src/routes/hook/use-api-error-handler";
import { AuthContext } from "./auth-context";
import { isValidToken, jwtDecode, setSession } from "./utils";
import { permissionIds } from "../../../config/config";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGIN") {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === "REGISTER") {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGOUT") {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = "accessToken";

export const Roles = {
  System: "system",
  SeniorMod: "senior moderator",
  Mod: "moderator",
  User: "user",
  lab215: "system",
};

const getUser = async (jwt) => {
  const decoded = jwtDecode(jwt);

  const isUser = decoded.id.length === 17;
  const user = {};

  switch (decoded.permissions[0]) {
    case permissionIds.Owner:
      user.role = Roles.System;
      break;
    case permissionIds.Systems:
      user.role = Roles.System;
      break;
    case permissionIds.SeniorModerators:
      user.role = Roles.SeniorMod;
      break;
    case permissionIds.Moderators:
      user.role = Roles.Mod;
      break;
    case permissionIds.User:
      user.role = Roles.User;
      break;
    case permissionIds.lab215:
      user.role = Roles.lab215;
      break;
    default:
      throw new Error("Unknown Role!");
  }
  user.displayName = decoded.username;

  if (isUser) {
    user.photoURL = decoded.picture;
    user.id = decoded.id;
    user.discord = !!decoded.discordId;
  } else {
    user.photoURL = decoded.picture;
    user.id = decoded.id;
  }
  return user;
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const errorHandler = useApiErrorHandler();
  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(jwtDecode(accessToken))) {
        setSession(accessToken);
        const user = await getUser(accessToken);
        dispatch({
          type: "INITIAL",
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      setSession(null);
      dispatch({
        type: "INITIAL",
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (token, setLoading = () => {}) => {
      try {
        setSession(token);
        setLoading(true);
        const user = await getUser(token);
        dispatch({
          type: "LOGIN",
          payload: {
            user,
          },
        });
        return true;
      } catch (error) {
        errorHandler(error, () => login(token, setLoading));
        console.error(error);
        setSession(null);
        dispatch({
          type: "INITIAL",
          payload: {
            user: null,
          },
        });
        setLoading(false);
        return false;
      }
    },
    [errorHandler]
  );

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    localStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? "authenticated" : "unauthenticated";

  const status = state.loading ? "loading" : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: "jwt",
      loading: status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
