// ----------------------------------------------------------------------

export const ROOTS = {
  DASHBOARD: "/dashboard",
  USER: "/user",
  MOD: "/moderators",
  SENIOR: "/senior",
  OVERVIEW: "/overview",
  SYSTEMS: "/systems",
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `/login`,
    },
  },
  user: {
    myReports: ROOTS.USER,
    createReport: `${ROOTS.USER}/create-report`,
  },
  mod: {
    reports: ROOTS.MOD,
    assignedReports: `${ROOTS.MOD}/assigned-reports`,
    closedReports: `${ROOTS.MOD}/closed-reports`,
  },
  senior: {
    overview: ROOTS.SENIOR,
    status: `${ROOTS.SENIOR}/report-status`,
  },
  systems: {
    controlPanel: ROOTS.SYSTEMS,
  },
};
