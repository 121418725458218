// routes
import { ContextAPI, ReportsAPI } from "src/auth/AxiosAPI";
import { paths } from "src/routes/paths";

// ----------------------------------------------------------------------

export function jwtDecode(token) {
  const splitted = token.split(".");
  if (splitted.length < 2) {
    throw new Error("Invalid Token!");
  }
  const base64Url = splitted[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (decodedToken) => {
  if (!decodedToken) {
    return false;
  }

  const currentTime = Date.now() / 1000;

  return decodedToken.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert("Token expired");

    localStorage.removeItem("accessToken");

    window.location.href = paths.auth.jwt.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);

    ReportsAPI.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    ContextAPI.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");

    delete ReportsAPI.defaults.headers.common.Authorization;
    delete ContextAPI.defaults.headers.common.Authorization;
  }
};
