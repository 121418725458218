const permissionIds = {
  Owner: "4",
  Systems: "66",
  SeniorModerators: "74",
  Moderators: "62",
  User: "0",
  lab215: "92",
};

const webSocketURL = "wss://reports-api.rustafied.com/";

module.exports = { permissionIds, webSocketURL };
