/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
// @mui
// import { useTheme } from '@mui/material/styles'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
// routes
import { RouterLink } from 'src/routes/components'

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  /*
  const theme = useTheme()

  const PRIMARY_LIGHT = theme.palette.primary.light

  const PRIMARY_MAIN = theme.palette.primary.main

  const PRIMARY_DARK = theme.palette.primary.dark
  */

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component='div'
      sx={{
        width: 180,
        height: 90,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <img src={require('src/assets/rustafied.png')} alt='Rustafied' />
    </Box>
  )

  if (disabledLink) {
    return logo
  }

  return (
    <Link component={RouterLink} href='/' sx={{ display: 'contents' }}>
      {logo}
    </Link>
  )
})

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
}

export default Logo
