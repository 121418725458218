import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Iconify from 'src/components/iconify/iconify'

const retryCodes = [408, 500, 502, 503, 504]
const notRetryCodes = [400, 401, 403, 404, 405, 406, 407, 410, 411, 413, 414, 429]

export const useApiErrorHandler = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const handleError = useCallback(
    (error, retry) => {
      let message = error.response?.data?.message || error.message || 'Unkown Error!'
      let shouldRetry = Boolean(!error.response?.data?.message)
      if (error.response) {
        if (retryCodes.includes(error.response.status)) {
          shouldRetry = true
        }
        if (notRetryCodes.includes(error.response.status)) {
          shouldRetry = false
        }
      } else if (error.request) {
        // The request was made but no response was received
        message = error.message || 'No response received from the server.'
        shouldRetry = true
      } else {
        // Something happened in setting up the request that triggered an Error, could be js run time error
        shouldRetry = false
        message = error.message || 'Unkown Error!'
      }
      const enqueueOptions = {
        message,
        variant: 'error',
        key: Math.random(), //  required
        persist: shouldRetry,
      }
      if (shouldRetry) {
        enqueueOptions.action = key => (
          <Stack flexDirection='row' spacing={1}>
            <Button
              onClick={() => {
                retry()
                closeSnackbar(key)
              }}
              color='primary'
              variant='contained'
            >
              Retry
            </Button>
            <IconButton onClick={() => closeSnackbar(key)}>
              <Iconify icon='tabler:x' />
            </IconButton>
          </Stack>
        )
      }
      enqueueSnackbar(enqueueOptions)
    },
    [enqueueSnackbar, closeSnackbar]
  )

  return handleError
}
