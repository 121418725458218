import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// auth
import { GuestGuard } from "src/auth/guard";
// layouts
import AuthClassicLayout from "src/layouts/auth/classic";
// components
import { SplashScreen } from "src/components/loading-screen";

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import("src/pages/auth/jwt/login"));

const JwtForumLoginPage = lazy(() => import("src/pages/auth/jwt/forumLogin"));

const CodeLoginPage = lazy(() => import("src/pages/auth/jwt/codeLogin"));

// ----------------------------------------------------------------------

const authJwt = {
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: "/login/",
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: "staff",
      element: (
        <AuthClassicLayout>
          <JwtForumLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: "api/v1/auth/jwt/steam",
      element: (
        <AuthClassicLayout>
          <CodeLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: "api/v1/auth/jwt/forum",
      element: (
        <AuthClassicLayout>
          <CodeLoginPage isForum />
        </AuthClassicLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: "/",
    children: [authJwt],
  },
];
