import { useMemo } from "react";
// routes
import { paths } from "src/routes/paths";
// components
import SvgColor from "src/components/svg-color";
import { useAuthContext } from "src/auth/hooks";
import { Roles } from "src/auth/context/jwt/auth-provider";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon("ic_job"),
  blog: icon("ic_blog"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  tour: icon("ic_tour"),
  order: icon("ic_order"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  product: icon("ic_product"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
};

// ----------------------------------------------------------------------

const getNavData = (role) => {
  switch (role) {
    case Roles.User:
      return [
        {
          subheader: "Reports",
          items: [
            {
              title: "My Reports",
              path: paths.user.myReports,
              icon: ICONS.user,
            },
            {
              title: "Create a Report",
              path: paths.user.createReport,
              icon: ICONS.mail,
            },
          ],
        },
      ];
    case Roles.Mod:
      return [
        {
          subheader: "Moderators",
          items: [
            {
              title: "Report Handling",
              path: paths.mod.reports,
              icon: ICONS.user,
            },
            {
              title: "Assigned Reports",
              path: paths.mod.assignedReports,
              icon: ICONS.file,
            },
            {
              title: "Closed Reports",
              path: paths.mod.closedReports,
              icon: ICONS.disabled,
            },
          ],
        },
      ];
    case Roles.SeniorMod:
    // eslint-disable-next-line no-fallthrough
    case Roles.System:
      return [
        {
          subheader: "Moderators",
          items: [
            {
              title: "Report Handling",
              path: paths.mod.reports,
              icon: ICONS.user,
            },
            {
              title: "Assigned Report",
              path: paths.mod.assignedReports,
              icon: ICONS.file,
            },
            {
              title: "Closed Report",
              path: paths.mod.closedReports,
              icon: ICONS.disabled,
            },
          ],
        },
        {
          subheader: "Senior Moderators",
          items: [
            {
              title: "Activity Overview",
              path: paths.senior.overview,
              icon: ICONS.analytics,
            },
            {
              title: "Report Status",
              path: paths.senior.status,
              icon: ICONS.dashboard,
            },
          ],
        },
        {
          subheader: "Systems",
          items: [
            {
              title: "Control Panel",
              path: paths.systems.controlPanel,
              icon: ICONS.lock,
            },
          ],
        },
      ];
    default:
      return [];
  }
};

export function useNavData() {
  const { user } = useAuthContext();
  const data = useMemo(() => getNavData(user?.role), [user?.role]);

  return data;
}
