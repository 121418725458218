import LabelTemplate, { actions } from "../LabelTemplate";

export default function UserActionTemplate({ action }) {
  if (action === "OFFLINE") {
    action = "NO_ACTION";
  }

  const foundAction = actions.find((s) => s.value === action) || {
    value: "---",
    color: "default",
  };
  return <LabelTemplate text={foundAction.value} color={foundAction.color} />;
}
