import IconButton from "@mui/material/IconButton";
import React from "react";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Iconify from "../iconify/iconify";

// eslint-disable-next-line react/prop-types
export default function ViewTemplate({ href = "/", target = "_self" }) {
  return (
    <Tooltip placement="top" arrow title="View Ticket">
      <Link to={href} target={target}>
        <IconButton>
          <Iconify icon="tabler:search" />
        </IconButton>
      </Link>
    </Tooltip>
  );
}
