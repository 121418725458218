import { fToNow } from "src/utils/format-time";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import PlayerTemplate from "./GridCellTemplates/PlayerTemplate";
import StatusTemplate from "./GridCellTemplates/StatusTemplate";
import ReasonTemplate from "./GridCellTemplates/ReasonTemplate";
import ActionTemplate from "./GridCellTemplates/ActionTemplate";
import React from "react";
import { ReportsAPI } from "../auth/AxiosAPI";
import UserStatusTemplate from "./GridCellTemplates/UserStatusTemplate";
import UserActionTemplate from "./GridCellTemplates/UserActionTemplate";
import { Typography } from "@mui/material";
import Star from "@mui/icons-material/Star";
import StarHalf from "@mui/icons-material/StarHalf";
import StarOutline from "@mui/icons-material/StarOutline";
import { NoteCell } from "./NoteCell";
import { EvidenceCell } from "./EvidenceCell";
import SmallPlayerTemplate from "./GridCellTemplates/SmallPlayerTemplate";

function ModeratorCell({ row }) {
  const [moderatorName, setModeratorName] = React.useState(null);

  React.useEffect(() => {
    if (row.status === "INVESTIGATING" || row.status === "CLOSED") {
      ReportsAPI.get(`/admin/moderators/${row.moderator_id}`).then((res) => {
        setModeratorName(res.data.name);
      });
    }
  }, [row]);

  return <>{moderatorName}</>;
}

const renderStarRating = (rating) => {
  const fullStars = Math.floor((rating * 10) / 2);
  const halfStars = (rating * 10) % 2;
  const emptyStars = 5 - fullStars - (halfStars ? 1 : 0);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {Array(fullStars)
        .fill(null)
        .map((_, index) => (
          <Star
            key={`full-${index}`}
            fontSize="small"
            style={{ color: "gold" }}
          />
        ))}
      {halfStars ? (
        <StarHalf key="half-1" fontSize="small" style={{ color: "gold" }} />
      ) : null}
      {Array(emptyStars)
        .fill(null)
        .map((_, index) => (
          <StarOutline
            key={`empty-${index}`}
            fontSize="small"
            style={{ color: "grey" }}
          />
        ))}
    </div>
  );
};

export const ageProps = {
  field: "createdAt",
  sortable: true,
  headerName: "Report Age",
  headerAlign: "center",
  minWidth: 130,
  flex: 1,
  align: "center",
  valueFormatter: (params) => (params.value ? fToNow(params.value) : "Unknown"),
};

export const ratingProps = {
  field: "reporterRating",
  sortable: true,
  headerName: "Reporter Rating",
  width: 150,
  renderCell: (params) =>
    renderStarRating(params.row?.reporter?.success_rate || 0),
};

export const reporterProps = {
  field: "reporter",
  sortable: false,
  headerName: "Reporter",
  width: 200,
  renderCell: (params) => (
    <PlayerTemplate
      name={params.row?.reporter?.username}
      avatarSrc={params.row?.reporter?.picture}
      steamID={params.row?.reporter?.steam_id}
    />
  ),
};

export const smallReporterProps = {
  field: "reporter",
  sortable: false,
  headerName: "Reporter",
  width: 130,
  renderCell: (params) => (
    <SmallPlayerTemplate
      name={params.row?.reporter?.username}
      steamID={params.row?.reporter?.steam_id}
    />
  ),
};

export const suspectProps = {
  field: "suspect",
  sortable: false,
  headerName: "Primary Suspect",
  minWidth: 200,
  headerAlign: "center",
  align: "center",
  flex: 1,
  renderCell: ({ row }) => (
    <PlayerTemplate
      isGroup={row?.secondary_suspects?.length > 0}
      tooltip={
        row?.secondary_suspects?.length > 0
          ? `${1 + (row?.secondary_suspects?.length || 0)} suspects`
          : undefined
      }
      name={row?.primary_suspect?.username}
      avatarSrc={row?.primary_suspect?.picture}
      steamID={row?.primary_suspect?.steam_id}
    />
  ),
};

export const smallSuspectProps = {
  field: "suspect",
  sortable: false,
  headerName: "Primary Suspect",
  width: 130,
  headerAlign: "center",
  align: "center",
  renderCell: ({ row }) => (
    <SmallPlayerTemplate
      name={row?.primary_suspect?.username}
      steamID={row?.primary_suspect?.steam_id}
      isGroup={row?.secondary_suspects?.length > 0}
      tooltip={
        row?.secondary_suspects?.length > 0
          ? `${1 + (row?.secondary_suspects?.length || 0)} suspects`
          : undefined
      }
    />
  ),
};

export const moderatorProps = {
  field: "moderator",
  headerName: "Assigned To",
  sortable: false,
  minWidth: 150,
  headerAlign: "center",
  align: "center",
  flex: 1,
  renderCell: ({ row }) => {
    let moderatorName = row.moderator;
    if (typeof moderatorName === "string") {
      moderatorName = moderatorName.replace(/[-_]?rustafied/i, "");
    }
    return <ModeratorCell row={{ ...row, moderator: moderatorName }} />;
  },
};

export const expiredModProps = {
  field: "moderator",
  headerName: "Handled By",
  sortable: false,
  minWidth: 200,
  headerAlign: "center",
  align: "center",
  flex: 1,
  renderCell: ({ row }) => {
    return <ModeratorCell row={row} />;
  },
};

export const serverProps = {
  field: "server",
  headerName: "Server",
  minWidth: 100,
  headerAlign: "center",
  align: "center",
  flex: 1,
  sortable: false,
  renderCell: (params) => {
    let serverName = params.formattedValue || params.value;
    if (typeof serverName === "string") {
      serverName = serverName
        .replace("Rustafied.com - ", "")
        .replace("-Monday", "");
    }
    return <Typography variant="body2">{serverName}</Typography>;
  },
};

export const statusProps = {
  field: "status",
  headerName: "Status",
  sortable: false,

  minWidth: 130,
  align: "center",
  headerAlign: "center",
  renderCell: ({ row }) => <StatusTemplate status={row?.status} />,
  flex: 1,
};

export const userStatusProps = {
  field: "status",
  headerName: "Status",
  sortable: false,

  minWidth: 130,
  align: "center",
  headerAlign: "center",
  renderCell: ({ row }) => <UserStatusTemplate status={row?.status} />,
  flex: 1,
};

export const actionProps = {
  field: "action",
  headerName: "Action",
  sortable: false,
  minWidth: 130,
  align: "center",
  headerAlign: "center",
  renderCell: ({ row }) => {
    if (row.status !== "PENDING" && row.status !== "INVESTIGATING") {
      return <ActionTemplate action={row?.action} />;
    }
    return null;
  },
  flex: 1,
};

export const userActionProps = {
  field: "action",
  headerName: "Action",
  sortable: false,
  minWidth: 130,
  align: "center",
  headerAlign: "center",
  renderCell: ({ row }) => {
    if (row.status !== "PENDING" && row.status !== "INVESTIGATING") {
      return <UserActionTemplate action={row?.action} />;
    }
    return null;
  },
  flex: 1,
};

export const viewProps = {
  field: "view",
  headerName: "View",
  sortable: false,
  minWidth: 100,
  headerAlign: "center",
  align: "center",
};

export const reasonProps = {
  minWidth: 130,
  maxWidth: 150,
  field: "reason",
  headerName: "Reason",
  headerAlign: "center",
  align: "center",
  sortable: false,
  flex: 1,
  renderCell: ({ row }) => <ReasonTemplate reason={row?.reason} />,
};

export const notesProps = {
  minWidth: 130,
  maxWidth: 200,
  field: "notes",
  headerName: "Note",
  headerAlign: "center",
  align: "center",
  sortable: false,
  flex: 1,
  renderCell: ({ row }) => <NoteCell row={row} />,
};

export const evidenceProps = {
  minWidth: 130,
  maxWidth: 200,
  field: "evidence",
  headerName: "Evidence",
  headerAlign: "center",
  align: "center",
  sortable: "false",
  flex: 1,
  renderCell: ({ row }) => <EvidenceCell row={row} />,
};

export const reporterSuccessRateProps = {
  field: "reporter_success_rate",
  sortable: false,
  headerName: "Rating",
  headerAlign: "center",
  align: "center",
  minWidth: 150,
  renderCell: ({ row }) => (
    <Tooltip
      arrow
      placement="top"
      title={
        row?.reporter?.success_rate !== undefined
          ? `${row.reporter.success_rate} out of 5`
          : "Unkown"
      }
    >
      <Box>
        <Rating
          precision={0.1}
          readOnly
          value={row?.reporter?.success_rate}
          size="small"
        />
      </Box>
    </Tooltip>
  ),
};

export const getSuspectsQuery = (row) => {
  const primarySuspectId = row?.primary_suspect?.steam_id;
  const secondarySuspectIds =
    row?.secondary_suspects?.map((s) => s.steam_id) || [];

  return `suspects=${[primarySuspectId, ...secondarySuspectIds]
    .filter(Boolean)
    .join(",")}`;
};
