import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faImage } from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import { getContentType } from "../utils/getContentType";

export const EvidenceCell = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [contentType, setContentType] = useState(null);

  useEffect(() => {
    const fetchContentType = async () => {
      if (!row?.evidence) return;

      const type = await getContentType(row?.evidence);
      setContentType(type);
    };

    fetchContentType();
  }, [row]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "evidence-popover" : undefined;

  const isVideo = contentType === "video";

  return (
    <Typography fontSize={22}>
      <>
        {row?.evidence ? (
          <IconButton aria-describedby={id} onClick={handleClick}>
            <FontAwesomeIcon icon={isVideo ? faVideo : faImage} />
          </IconButton>
        ) : (
          "---"
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div style={{ overflow: "auto" }}>
            {isVideo ? (
              <ReactPlayer url={row?.evidence} controls />
            ) : (
              <img
                src={row.evidence}
                alt="Evidence"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            )}
            <Typography variant="body2" style={{ marginTop: 10 }}>
              <a href={row?.evidence} target="_blank" rel="noopener noreferrer">
                {row?.evidence}
              </a>
            </Typography>
          </div>
        </Popover>
      </>
    </Typography>
  );
};
